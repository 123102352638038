import React, { useState } from "react"
import { rhythm } from "../utils/typography"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBars,
  faGlobeAmericas,
  faQuestionCircle,
  faFileSignature,
  faMailBulk,
} from "@fortawesome/free-solid-svg-icons"
import { Button } from "semantic-ui-react"
import logo from "../icons/logo.png"

export const TopHeader = () => {
  const [showMenu, setShowMenu] = useState(false)

  const toggleMenu = () => {
    setShowMenu(!showMenu)
  }

  return (
    <div className="top-header">
      <div
        style={{
          maxWidth: rhythm(47),
          padding: `${0} ${rhythm(3 / 4)}`,
          margin: "0 auto",
        }}
        className="menu"
      >
        <Link to="/">
          <img alt="declaration logo" src={logo} />
        </Link>
        <Button className="hamburger" onClick={toggleMenu}>
          <FontAwesomeIcon color="#efefef" icon={faBars} />
        </Button>
        <ul className={showMenu ? undefined : "mobile-hidden"}>
          <li>
            <Link to="/" className="dark-purple">
              <FontAwesomeIcon icon={faGlobeAmericas} />
              &nbsp;&nbsp;The Declaration
            </Link>
          </li>
          {/* <li>
            <Link
              to="/#sign"
              className="purple"
              onClick={() => {
                setShowMenu(false)
              }}
            >
              <FontAwesomeIcon icon={faFileSignature} />
              &nbsp;&nbsp;Sign
            </Link>
          </li> */}
          <li>
            <Link to="/faq" className="light-green">
              <FontAwesomeIcon icon={faQuestionCircle} />
              &nbsp;&nbsp;FAQ
            </Link>
          </li>
          {/* <li>
            <Link to="/contact" className="green">
              <FontAwesomeIcon icon={faMailBulk} />
              &nbsp;&nbsp;Contact
            </Link>
          </li> */}
        </ul>
      </div>
    </div>
  )
}
